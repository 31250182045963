import { type FunctionComponent } from "react";

export const Avatar03: FunctionComponent = () => (
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_782_1236)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.7501 16.78C84.7101 13.9 100.45 10.74 117.41 21.42C135.52 32.82 137.11 51.48 135.03 89.08C135.03 89.08 147.91 102.25 149.67 115.74C152.6 138.18 124.1 152.89 91.1901 152.13C51.5301 151.21 27.9601 138.17 31.3301 116.37C33.4101 102.93 47.3501 89.99 47.3501 89.99C45.2601 52.39 46.1401 35 62.4401 21.09C68.5801 15.85 73.2901 15.35 76.7701 16.78H76.7501Z"
        fill="#C3DFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.94 111.88C106.94 102.77 99.5399 95.37 90.4299 95.37C81.3199 95.37 73.9199 102.77 73.9199 111.88V147.8C73.9199 156.91 81.3199 164.31 90.4299 164.31C99.5399 164.31 106.94 156.91 106.94 147.8V111.88Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.88 80.44C112.44 80.44 108.02 84.86 108.02 90.3C108.02 95.74 112.44 100.16 117.88 100.16H128.13C133.57 100.16 137.99 95.74 137.99 90.3C137.99 84.86 133.57 80.44 128.13 80.44H117.88Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.87 96.9C125.87 96.9 127.36 91.22 132.94 90.92M127.58 93.35C127.58 93.35 126.46 87.58 131.35 84.9"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.9899 81.05C68.4299 81.05 72.8499 85.47 72.8499 90.91C72.8499 96.35 68.4299 100.77 62.9899 100.77H52.7399C47.2999 100.77 42.8799 96.35 42.8799 90.91C42.8799 85.47 47.2999 81.05 52.7399 81.05H62.9899Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.0002 96.92C55.0002 96.92 53.5002 91.24 47.9302 90.94M53.2902 93.36C53.2902 93.36 54.4102 87.59 49.5202 84.91"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M123 67.15C123 49.18 108.41 34.58 90.4299 34.58C72.4499 34.58 57.8599 49.17 57.8599 67.15V99.99C57.8599 117.97 72.4499 132.56 90.4299 132.56C108.41 132.56 123 117.97 123 99.99V67.15Z"
        fill="white"
      />
      <mask
        id="mask0_782_1236"
        maskUnits="userSpaceOnUse"
        x="57"
        y="34"
        width="66"
        height="99">
        <path d="M123 67.15C123 49.18 108.41 34.58 90.4299 34.58C72.4499 34.58 57.8599 49.17 57.8599 67.15V99.99C57.8599 117.97 72.4499 132.56 90.4299 132.56C108.41 132.56 123 117.97 123 99.99V67.15Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_782_1236)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.6099 88.49C74.0799 88.49 75.2799 90.25 75.2799 92.42C75.2799 94.59 74.0799 96.35 72.6099 96.35C71.1399 96.35 69.9399 94.59 69.9399 92.42C69.9399 90.25 71.1399 88.49 72.6099 88.49Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.26 88.48C109.73 88.48 110.93 90.24 110.93 92.41C110.93 94.58 109.73 96.34 108.26 96.34C106.79 96.34 105.59 94.58 105.59 92.41C105.59 90.24 106.79 88.48 108.26 88.48Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.0701 115.53C85.0701 117.49 87.4701 119.07 90.4301 119.07C93.3901 119.07 95.7901 117.48 95.7901 115.53H85.0601H85.0701Z"
          fill="black"
        />
        <path
          d="M103.93 89.32C103.93 89.32 108.79 86.02 113.19 89.45M103.93 83.39C103.93 83.39 108.79 80.08 113.19 83.52M85.8202 108.15C85.8202 108.15 90.6802 111.45 95.0802 108.02M76.9602 89.32C76.9602 89.32 72.1002 86.02 67.7002 89.45M76.9602 83.39C76.9602 83.39 72.1002 80.08 67.7002 83.52"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.98 102.4C116.32 101.19 119.38 102.22 119.81 104.69C120.25 107.16 117.89 110.15 114.56 111.36C111.23 112.57 108.16 111.54 107.73 109.07C107.29 106.6 109.65 103.61 112.98 102.4Z"
          fill="#C3DFCF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.8901 102.4C64.5501 101.19 61.4901 102.22 61.0601 104.69C60.6201 107.16 62.9801 110.15 66.3101 111.36C69.6501 112.57 72.7101 111.54 73.1401 109.07C73.5801 106.6 71.2201 103.61 67.8901 102.4Z"
          fill="#C3DFCF"
        />
      </g>
      <path
        d="M123 67.15C123 49.18 108.41 34.58 90.4299 34.58C72.4499 34.58 57.8599 49.17 57.8599 67.15V99.99C57.8599 117.97 72.4499 132.56 90.4299 132.56C108.41 132.56 123 117.97 123 99.99V67.15Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.0999 64.46C55.1599 64.41 87.8599 82.57 107.36 64.32C119.37 53.09 127.02 69.84 126.7 78.23C126.7 78.23 131.26 56.44 126.62 46.7C121.98 36.96 90.5099 25.65 90.5099 25.65L56.0999 64.46Z"
        fill="#C3DFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4001 52.29C97.4001 52.29 89.8701 61.99 73.6701 64.32C57.4701 66.65 52.6701 72.05 52.9901 80.44C52.9901 80.44 49.7701 56.44 54.4101 46.7C59.0501 36.96 91.8201 25.48 91.8201 25.48L97.4001 52.29Z"
        fill="#C3DFCF"
      />
    </g>
    <defs>
      <clipPath id="clip0_782_1236">
        <rect
          width="118.87"
          height="151.3"
          fill="white"
          transform="translate(31 14)"
        />
      </clipPath>
    </defs>
  </svg>
);
