import { type FunctionComponent } from "react";

export const CasesIcon: FunctionComponent<{ readonly size?: number }> = ({
  size = 16,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none">
    <path d="M16.6665 4.99984H14.1665V3.33317C14.1665 2.414 13.419 1.6665 12.4998 1.6665H7.49984C6.58067 1.6665 5.83317 2.414 5.83317 3.33317V4.99984H3.33317C2.414 4.99984 1.6665 5.74734 1.6665 6.6665V15.8332C1.6665 16.7523 2.414 17.4998 3.33317 17.4998H16.6665C17.5857 17.4998 18.3332 16.7523 18.3332 15.8332V6.6665C18.3332 5.74734 17.5857 4.99984 16.6665 4.99984ZM13.3332 6.6665V15.8332H6.6665V6.6665H13.3332ZM12.4998 3.33317V4.99984H7.49984V3.33317H12.4998ZM3.33317 6.6665H4.99984V15.8332H3.33317V6.6665ZM14.9998 15.8332V6.6665H16.6665L16.6673 15.8332H14.9998Z" fill="currentColor"/>
  </svg>
);
