import { type FunctionComponent } from "react";

export const BookmarkBook: FunctionComponent<{ readonly size?: number }> = ({
  size = 16,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none">
    <path d="M15.8333 1.6748H5C3.995 1.6748 2.5 2.34064 2.5 4.1748V15.8415C2.5 17.6756 3.995 18.3415 5 18.3415H17.5V16.6748H5.01C4.625 16.6648 4.16667 16.5123 4.16667 15.8415C4.16667 15.7573 4.17417 15.6823 4.18667 15.614C4.28 15.1348 4.6725 15.0165 5.00917 15.0081H16.6667C16.6817 15.0081 16.6925 15.0006 16.7075 14.9998H17.5V3.34147C17.5 2.4223 16.7525 1.6748 15.8333 1.6748ZM15.8333 13.3415H4.16667V4.1748C4.16667 3.50314 4.625 3.35147 5 3.34147H10.8333V9.17481L12.5 8.34147L14.1667 9.17481V3.34147H15.8333V13.3415Z" fill="currentColor"/>
  </svg>
);
