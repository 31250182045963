import { type FunctionComponent } from "react";

export const Avatar04: FunctionComponent = () => (
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_782_1258)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.87 23.81C70.36 19.99 86.86 13.51 100.1 15.31C110.5 16.72 103.68 25.78 111.27 30.52C118.22 34.85 124.48 26.53 129.51 36.55C134.22 45.93 129.85 66.65 128.79 85.63C128.79 85.63 122.57 77.98 124.42 89.87C125.48 96.69 126.45 93.02 132.82 105.32C135.06 109.65 128.23 115.21 119.68 113.29C106.71 110.39 89.25 97.89 89.25 97.89L71.87 23.81Z"
        fill="#BA3333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.0302 30.44C84.0302 30.44 86.9402 24.27 84.0302 21.34C78.3902 15.7 64.9002 12.94 63.9302 25.46C63.2302 34.45 55.5802 30.86 51.2602 33.48C45.7502 36.83 43.3702 47.25 49.3802 52.74C52.9902 56.03 49.6502 63.34 49.7602 69.65C49.8502 74.69 50.1102 80.25 50.4502 86.43C50.4502 86.43 54.6702 86.84 56.8802 88.25C74.3702 99.42 86.2702 91.7 86.2702 91.7L84.0202 30.43L84.0302 30.44Z"
        fill="#BA3333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.06 110.85C106.06 101.74 98.66 94.34 89.55 94.34C80.44 94.34 73.04 101.74 73.04 110.85V146.77C73.04 155.88 80.44 163.28 89.55 163.28C98.66 163.28 106.06 155.88 106.06 146.77V110.85Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117 79.41C111.56 79.41 107.14 83.83 107.14 89.27C107.14 94.71 111.56 99.13 117 99.13H127.25C132.69 99.13 137.11 94.71 137.11 89.27C137.11 83.83 132.69 79.41 127.25 79.41H117Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.99 95.88C124.99 95.88 126.48 90.2 132.06 89.9M126.7 92.32C126.7 92.32 125.58 86.55 130.47 83.87"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.11 80.02C67.55 80.02 71.97 84.44 71.97 89.88C71.97 95.32 67.55 99.74 62.11 99.74H51.86C46.42 99.74 42 95.32 42 89.88C42 84.44 46.42 80.02 51.86 80.02H62.11Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.1198 95.9C54.1198 95.9 52.6198 90.22 47.0498 89.92M52.4098 92.34C52.4098 92.34 53.5298 86.57 48.6398 83.89"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.13 66.12C122.13 48.15 107.54 33.55 89.5602 33.55C71.5802 33.55 56.9902 48.15 56.9902 66.12V98.96C56.9902 116.93 71.5802 131.53 89.5602 131.53C107.54 131.53 122.13 116.94 122.13 98.96V66.12Z"
        fill="white"
      />
      <mask
        id="mask0_782_1258"
        maskUnits="userSpaceOnUse"
        x="56"
        y="33"
        width="67"
        height="99">
        <path d="M122.13 66.12C122.13 48.15 107.54 33.55 89.5602 33.55C71.5802 33.55 56.9902 48.15 56.9902 66.12V98.96C56.9902 116.93 71.5802 131.53 89.5602 131.53C107.54 131.53 122.13 116.94 122.13 98.96V66.12Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_782_1258)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.7301 87.46C73.2001 87.46 74.4001 89.22 74.4001 91.39C74.4001 93.56 73.2001 95.32 71.7301 95.32C70.2601 95.32 69.0601 93.56 69.0601 91.39C69.0601 89.22 70.2601 87.46 71.7301 87.46Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.38 87.46C108.85 87.46 110.05 89.22 110.05 91.39C110.05 93.56 108.85 95.32 107.38 95.32C105.91 95.32 104.71 93.56 104.71 91.39C104.71 89.22 105.91 87.46 107.38 87.46Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.1902 114.51C84.1902 116.46 86.5902 118.05 89.5502 118.05C92.5102 118.05 94.9102 116.47 94.9102 114.51H84.1802H84.1902Z"
          fill="black"
        />
        <path
          d="M103.05 88.29C103.05 88.29 107.91 84.99 112.31 88.42M103.05 82.36C103.05 82.36 107.91 79.06 112.31 82.49M84.9403 107.12C84.9403 107.12 89.8003 110.43 94.2003 106.99M76.0803 88.29C76.0803 88.29 71.2203 84.99 66.8203 88.42M76.0803 82.36C76.0803 82.36 71.2203 79.06 66.8203 82.49"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.1 101.38C115.44 100.17 118.5 101.2 118.93 103.67C119.37 106.14 117.01 109.13 113.68 110.34C110.35 111.55 107.28 110.52 106.85 108.05C106.41 105.58 108.77 102.59 112.1 101.38Z"
          fill="#BA3333"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.0102 101.38C63.6702 100.17 60.6102 101.2 60.1802 103.67C59.7402 106.14 62.1002 109.13 65.4302 110.34C68.7702 111.55 71.8302 110.52 72.2602 108.05C72.7002 105.58 70.3402 102.59 67.0102 101.38Z"
          fill="#BA3333"
        />
      </g>
      <path
        d="M122.13 66.12C122.13 48.15 107.54 33.55 89.5602 33.55C71.5802 33.55 56.9902 48.15 56.9902 66.12V98.96C56.9902 116.93 71.5802 131.53 89.5602 131.53C107.54 131.53 122.13 116.94 122.13 98.96V66.12Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.2599 61.9C58.8099 61.88 66.5699 70.56 74.4199 63.71C78.5599 60.1 86.6099 51.11 87.5899 55.09C90.1299 65.52 100.35 56.34 105.48 51.58C110.52 46.91 108.4 64.58 114.41 60.76C122.29 55.75 117.88 65.52 120.21 68.79C122.99 72.7 123.7 76.57 123.62 78.57C123.62 78.57 126.41 54.8 122.26 46.19C118.11 37.58 89.9999 27.57 89.9999 27.57L59.2599 61.91V61.9Z"
        fill="#BA3333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1499 51.14C95.0999 46.32 75.0499 34.83 65.4799 59.42C60.1699 73.04 54.8799 70.78 55.1599 78.21C55.1599 78.21 53.5899 54.81 57.7399 46.19C61.8899 37.57 91.1699 27.42 91.1699 27.42L96.1599 51.14H96.1499Z"
        fill="#BA3333"
      />
    </g>
    <defs>
      <clipPath id="clip0_782_1258">
        <rect
          width="97.11"
          height="149.28"
          fill="white"
          transform="translate(41 15)"
        />
      </clipPath>
    </defs>
  </svg>
);
