import { type FunctionComponent } from "react";

export const Avatar02: FunctionComponent = () => (
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_782_1215)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.13 24.63C91.59 23 100.49 18.47 113.34 27.24C128.57 37.63 137.33 49.15 135.38 77.23C135.38 77.23 125.71 81.92 136.77 85.61C147.75 89.27 145.55 112.68 144.49 143.43L35.83 143.52C35.66 110.79 31.96 93.89 41.66 87.41C51.36 80.93 43.06 77.36 43.06 77.36C41.11 49.26 49.87 37.75 65.1 27.35C77.64 18.79 86.42 22.91 89.13 24.63Z"
        fill="#EBE361"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.71 105.48C106.71 96.37 99.3099 88.97 90.1999 88.97C81.0899 88.97 73.6899 96.37 73.6899 105.48V141.4C73.6899 150.51 81.0899 157.91 90.1999 157.91C99.3099 157.91 106.71 150.51 106.71 141.4V105.48Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.65 74.04C112.21 74.04 107.79 78.46 107.79 83.9C107.79 89.34 112.21 93.76 117.65 93.76H127.9C133.34 93.76 137.76 89.34 137.76 83.9C137.76 78.46 133.34 74.04 127.9 74.04H117.65Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125.64 90.51C125.64 90.51 127.13 84.83 132.71 84.53M127.35 86.96C127.35 86.96 126.23 81.19 131.12 78.51"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.7599 74.65C68.1999 74.65 72.6199 79.07 72.6199 84.51C72.6199 89.95 68.1999 94.37 62.7599 94.37H52.5099C47.0699 94.37 42.6499 89.95 42.6499 84.51C42.6499 79.07 47.0699 74.65 52.5099 74.65H62.7599Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.7702 90.53C54.7702 90.53 53.2702 84.85 47.7002 84.55M53.0602 86.97C53.0602 86.97 54.1802 81.2 49.2902 78.52"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.78 60.75C122.78 42.78 108.19 28.18 90.2101 28.18C72.2301 28.18 57.6401 42.78 57.6401 60.75V93.59C57.6401 111.57 72.2301 126.16 90.2101 126.16C108.19 126.16 122.78 111.57 122.78 93.59V60.75Z"
        fill="white"
      />
      <mask
        id="mask0_782_1215"
        maskUnits="userSpaceOnUse"
        x="57"
        y="28"
        width="66"
        height="99">
        <path d="M122.78 60.75C122.78 42.78 108.19 28.18 90.2101 28.18C72.2301 28.18 57.6401 42.78 57.6401 60.75V93.59C57.6401 111.57 72.2301 126.16 90.2101 126.16C108.19 126.16 122.78 111.57 122.78 93.59V60.75Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_782_1215)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.38 82.09C73.85 82.09 75.05 83.85 75.05 86.02C75.05 88.19 73.85 89.95 72.38 89.95C70.91 89.95 69.71 88.19 69.71 86.02C69.71 83.85 70.91 82.09 72.38 82.09Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108.03 82.09C109.5 82.09 110.7 83.85 110.7 86.02C110.7 88.19 109.5 89.95 108.03 89.95C106.56 89.95 105.36 88.19 105.36 86.02C105.36 83.85 106.56 82.09 108.03 82.09Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.8401 109.14C84.8401 111.09 87.2401 112.68 90.2001 112.68C93.1601 112.68 95.5601 111.09 95.5601 109.14H84.8301H84.8401Z"
          fill="black"
        />
        <path
          d="M103.7 82.92C103.7 82.92 108.56 79.61 112.96 83.05M103.7 76.99C103.7 76.99 108.56 73.69 112.96 77.12M85.5902 101.75C85.5902 101.75 90.4502 105.05 94.8502 101.62M76.7302 82.92C76.7302 82.92 71.8702 79.61 67.4702 83.05M76.7302 76.99C76.7302 76.99 71.8702 73.69 67.4702 77.12"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.75 96.01C116.09 94.8 119.15 95.83 119.58 98.3C120.02 100.77 117.66 103.76 114.33 104.97C111 106.18 107.93 105.15 107.5 102.68C107.06 100.21 109.42 97.21 112.75 96.01Z"
          fill="#EBE361"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.6601 96.01C64.3201 94.8 61.2601 95.83 60.8301 98.3C60.3901 100.77 62.7501 103.76 66.0801 104.97C69.4201 106.18 72.4801 105.15 72.9101 102.68C73.3501 100.21 70.9901 97.21 67.6601 96.01Z"
          fill="#EBE361"
        />
      </g>
      <path
        d="M122.78 60.75C122.78 42.78 108.19 28.18 90.2101 28.18C72.2301 28.18 57.6401 42.78 57.6401 60.75V93.59C57.6401 111.57 72.2301 126.16 90.2101 126.16C108.19 126.16 122.78 111.57 122.78 93.59V60.75Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.04 25C94.41 25.71 101.7 26.13 107.4 29.11C115.14 33.15 121.57 40.25 123.76 43.93C128.1 51.2 124.87 71.41 124.87 71.41C125.17 65.14 120.9 58.83 105.76 57.09C94.55 55.8 91.31 50.26 90.38 47.46C89.19 50.27 85.45 55.8 74.27 57.09C59.13 58.83 54.85 65.56 55.14 71.83C52.04 71.45 52.51 51.95 56.26 43.93C60.57 34.71 84.05 21.99 87.41 25.4L92.04 25Z"
        fill="#EBE361"
      />
    </g>
    <defs>
      <clipPath id="clip0_782_1215">
        <rect
          width="110.41"
          height="136.91"
          fill="white"
          transform="translate(35 22)"
        />
      </clipPath>
    </defs>
  </svg>
);
