import { type FunctionComponent } from "react";

export const Avatar07: FunctionComponent = () => (
  <svg
    width="180"
    height="180"
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_782_1320)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.6599 40.98C89.6599 40.98 87.7199 47.3 83.6199 47.98C79.5099 48.66 92.4999 51.48 101.39 44.59C101.39 44.59 101.81 49.82 96.4999 51.14C91.1899 52.45 101.88 52.99 108.9 48.93C115.92 44.87 119.45 61.01 119.64 65.69C119.95 73.24 125.9 75.33 126.88 70.33C127.01 69.68 127.15 66.83 127.15 66.01C127.13 62.25 128.1 20.6 93.0199 20.99C64.7999 21.3 64.6599 28.11 63.3999 33.55C62.1299 38.99 52.4499 36.01 52.9399 48.38C53.4299 60.75 50.8499 75.44 58.6399 74.88C64.7299 74.43 55.5699 46.61 89.6699 40.97L89.6599 40.98Z"
        fill="#C3DFCF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.06 101.5C106.06 92.39 98.66 84.99 89.55 84.99C80.44 84.99 73.04 92.39 73.04 101.5V137.42C73.04 146.53 80.44 153.93 89.55 153.93C98.66 153.93 106.06 146.53 106.06 137.42V101.5Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117 70.06C111.56 70.06 107.14 74.48 107.14 79.92C107.14 85.36 111.56 89.78 117 89.78H127.25C132.69 89.78 137.11 85.36 137.11 79.92C137.11 74.48 132.69 70.06 127.25 70.06H117Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.99 86.53C124.99 86.53 126.48 80.85 132.06 80.55M126.7 82.97C126.7 82.97 125.58 77.2 130.47 74.52"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.11 70.67C67.55 70.67 71.97 75.09 71.97 80.53C71.97 85.97 67.55 90.39 62.11 90.39H51.86C46.42 90.39 42 85.97 42 80.53C42 75.09 46.42 70.67 51.86 70.67H62.11Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.1198 86.55C54.1198 86.55 52.6198 80.87 47.0498 80.57M52.4098 82.99C52.4098 82.99 53.5298 77.22 48.6398 74.54"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.13 56.77C122.13 38.79 107.54 24.2 89.5602 24.2C71.5802 24.2 56.9902 38.8 56.9902 56.77V89.61C56.9902 107.59 71.5802 122.18 89.5602 122.18C107.54 122.18 122.13 107.59 122.13 89.61V56.77Z"
        fill="white"
      />
      <mask
        id="mask0_782_1320"
        maskUnits="userSpaceOnUse"
        x="56"
        y="24"
        width="67"
        height="99">
        <path d="M122.13 56.77C122.13 38.79 107.54 24.2 89.5602 24.2C71.5802 24.2 56.9902 38.8 56.9902 56.77V89.61C56.9902 107.59 71.5802 122.18 89.5602 122.18C107.54 122.18 122.13 107.59 122.13 89.61V56.77Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_782_1320)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.7305 78.11C73.2005 78.11 74.4005 79.87 74.4005 82.04C74.4005 84.21 73.2005 85.97 71.7305 85.97C70.2605 85.97 69.0605 84.21 69.0605 82.04C69.0605 79.87 70.2605 78.11 71.7305 78.11Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.38 78.11C108.85 78.11 110.05 79.87 110.05 82.04C110.05 84.21 108.85 85.97 107.38 85.97C105.91 85.97 104.71 84.21 104.71 82.04C104.71 79.87 105.91 78.11 107.38 78.11Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.1902 105.16C84.1902 107.12 86.5902 108.7 89.5502 108.7C92.5102 108.7 94.9102 107.12 94.9102 105.16H84.1802H84.1902Z"
          fill="black"
        />
        <path
          d="M103.05 78.94C103.05 78.94 107.91 75.64 112.31 79.07M103.05 73.01C103.05 73.01 107.91 69.71 112.31 73.14M84.9403 97.77C84.9403 97.77 89.8003 101.07 94.2003 97.64M76.0803 78.95C76.0803 78.95 71.2203 75.65 66.8203 79.08M76.0803 73.02C76.0803 73.02 71.2203 69.72 66.8203 73.15"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M122.13 56.77C122.13 38.79 107.54 24.2 89.5602 24.2C71.5802 24.2 56.9902 38.8 56.9902 56.77V89.61C56.9902 107.59 71.5802 122.18 89.5602 122.18C107.54 122.18 122.13 107.59 122.13 89.61V56.77Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.6601 40.98C89.6601 40.98 87.7201 47.3 83.6201 47.98C79.5101 48.66 92.5001 51.48 101.39 44.59C101.39 44.59 101.81 49.82 96.5001 51.14C91.1901 52.45 101.88 52.99 108.9 48.93C115.92 44.87 120.26 56.3 120.34 60.98C120.39 63.69 120.59 66.81 122.56 66.74C123.37 66.71 124.51 69.04 125.02 68.65C125.32 68.42 125.52 68.06 125.55 67.47C125.75 63.73 128.1 20.61 93.0201 21C64.8001 21.31 64.6601 28.12 63.4001 33.56C62.1301 39 53.6301 35.02 54.1101 47.39C54.3901 54.5 51.3901 67.69 56.8301 67.87C58.1701 67.91 58.5001 66.89 59.1901 65.11C61.6301 58.77 63.0601 45.38 89.6601 40.98Z"
        fill="#C3DFCF"
      />
    </g>
    <defs>
      <clipPath id="clip0_782_1320">
        <rect
          width="97.11"
          height="133.93"
          fill="white"
          transform="translate(41 21)"
        />
      </clipPath>
    </defs>
  </svg>
);
